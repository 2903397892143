import React, {Component} from 'react'
import App from 'base-shell/lib'
import MUIConfig from 'material-ui-shell/lib'
import merge from 'base-shell/lib/utils/config'
import _config from './config'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import reducers from './store/reducers'
import Amplify from 'aws-amplify'
import {awsConfig} from './awsConfig'
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import UserContextProvider from "./hooks/useUser";
import {BrowserRouter} from "react-router-dom";

const config = merge(MUIConfig, _config)
const store = createStore(reducers, applyMiddleware(thunk));
Amplify.configure(awsConfig)


if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://62a057b395fe42918d466b1b33a18f10@o952058.ingest.sentry.io/5958055",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.8,
    });
}

export default class Joyfully extends Component {
    componentWillMount() {
        console.log(process.env)
        if (window.location.protocol === "http:" && process.env.NODE_ENV == "production") {
            const secureUrl = window.location.href.replace("http:", "https:")
            window.location.href = secureUrl
        }
    }

    render() {
        return (
            <BrowserRouter>
                <UserContextProvider>
                    <Provider store={store}>
                        <App config={config}/>
                    </Provider>
                </UserContextProvider>
            </BrowserRouter>

        )
    }
}
