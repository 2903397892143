import COLORS from '../constants/colors';
const themes = [
  {
    id: 'joyfully',
    source: {
      palette: {
        primary: {
          light: COLORS.inactive,
          main: "#10545D",
          dark: "#010101CC",
          muted: "#01010163",
          green: '#10545D',
        },
        secondary: {
          main: COLORS.secondary
        }
      },
      background: COLORS.background,
      typography: {
        fontFamily: 'Nunito',
        fontWeight: 400,
        fontSize: 14,
        color: COLORS.dark,
        h1:{
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 500,
        },
        body2: {
          fontWeight: 400,
          fontSize: 14
        }
      },
      shape: {
        borderRadius: 15
      }
    }
  }
]

export default themes
