import React, {createContext, useContext, useEffect, useState} from "react"
import {storeItem, retrieveItem} from "../storage/storage";

import {Auth} from 'aws-amplify'

const UserContext = createContext({});

export const useUser = () => useContext(UserContext)

const UserContextProvider = ({children}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isDoneChecking, setIsDoneChecking] = useState(false)
    const [onboardingShown, setOnboardingShown] = useState(true)
    const [userData, setUserData] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [user, setUser] = useState(null)

    const checkUser = () => {
        setIsDoneChecking(false)
        setIsLoggedIn(false)
        try {
            const userData = retrieveItem('userData')
            if (!userData) {
              setIsDoneChecking(true)

              Auth.currentAuthenticatedUser()
                .then(user => {
                  const token = user.signInUserSession.idToken.jwtToken
                  const expiry = user.signInUserSession.idToken.payload.exp
                  const expirationDate = new Date(new Date().getTime() + parseInt(expiry) * 1000);
                  const groups = user.signInUserSession.idToken.payload["cognito:groups"];
                  const group = groups ? groups[0] : "member"

                  storeItem('userData', JSON.stringify({
                      expiryDate: expirationDate.toISOString(),
                      userId: user.id,
                      group,
                      token
                  }))

                  setIsLoggedIn(true)
                })
                .catch(() => console.log("Not signed in"));

            } else {
              const retrievedData = JSON.parse(userData);
              setUserData(retrievedData)


              const {token, expiryDate} = retrievedData;
              const expirationDate = new Date(expiryDate);
              if (expirationDate <= new Date() || !token) {
                  setIsDoneChecking(true)
                  return;
              }

              setIsLoggedIn(true)
              setIsDoneChecking(true)
            }

        } catch (e) {
            console.log(e)
            setIsDoneChecking(true)
        }


    }


    useEffect(() => {
      checkUser()
      setOnboardingShown(retrieveItem('onboardingShown'))
    }, [])

    return <UserContext.Provider value={{
        setUser,
        user,
        userEmail,
        setUserEmail,
        setUserPassword,
        userPassword,
        userData,
        isLoggedIn,
        isDoneChecking,
        checkUser,
        onboardingShown
    }}>
        {children}
    </UserContext.Provider>
}

export default UserContextProvider;
