import React, {lazy} from 'react'
import {Route} from 'react-router-dom'
import {
  ProtectedRoute,
  ProtectedUplifterRoute,
  UnauthenticatedUserRoute,
  AuthenticatedUserRoute,
} from './ProtectedRoute'

import { TransitionGroup, CSSTransition } from "react-transition-group";

const Home = lazy(() => import('../pages/Home/Home'))
const SignIn = lazy(() => import('../pages/Auth/SignIn'))
const SignUp = lazy(() => import('../pages/Auth/SignUp'))
const SignOut = lazy(() => import('../pages/Auth/SignOut'))
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'))
const ConfirmCode = lazy(() => import('../pages/Auth/ConfirmCode'))
const PasswordReset = lazy(() => import('../pages/Auth/PasswordReset'))
const Profile = lazy(() => import('../pages/Auth/Profile'))
const PersonalDetails = lazy(() => import('../pages/Auth/Profile/PersonalDetails'))
const UpdatePassword = lazy(() => import('../pages/Auth/Profile/UpdatePassword'))

const Notice = lazy(() => import('../pages/User/WhatIsJoyfully/Notice'))
const Uplifters = lazy(() => import('../pages/User/WhoAreUplifters/Uplifters'))
const RecordAudio = lazy(() => import('../pages/User/Recording/RecordAudio'))
const RecordingAudio = lazy(() => import('../pages/User/Recording/RecordingAudio'))
const RecordingComplete = lazy(() => import('../pages/User/Recording/RecordingComplete'))
const RecordingSent = lazy(() => import('../pages/User/Recording/RecordingSent'))
const MessageSent = lazy(() => import('../pages/Onboarding/Success'))
const Invite = lazy(() => import('../pages/User/Invite'))
const Help = lazy(() => import('../pages/Help'))
const Hotlines = lazy(() => import('../pages/MentalHealth'))
const Responses = lazy(() => import('../pages/User/Responses'))

//Uplifter
const VideoRules = lazy(() => import('../pages/Uplifter/VideoRules/VideoRules'))
const Feedback = lazy(() => import('../pages/Uplifter/FeedBack/Feedback'))
const Recordings = lazy(() => import('../pages/Uplifter/RecordingsList/Recordings'))
const UplifterSubmissions = lazy(() => import('../pages/Uplifter/Submissions'))
const Rate = lazy(() => import('../pages/Uplifter/Rating/Rate'))
const VideoRecordComplete = lazy(() => import('../pages/Uplifter/VideoRecording/RecordingComplete'))
const ResponseComplete = lazy(() => import('../pages/Uplifter/Respond/Complete'))
const VideoRecord = lazy(() => import('../pages/Uplifter/VideoRecording/RecordVideo'))
const VideoRecordSent = lazy(() => import('../pages/Uplifter/VideoRecording/RecordingSent'))
const LogOut = lazy(() => import('../pages/Uplifter/SignOut/SignOut'))

const Respond = lazy(() => import('../pages/Uplifter/Respond/'))
const Onboarding = lazy(() => import('../pages/Onboarding/Onboarding'))
const Success = lazy(() => import('../pages/Onboarding/Success'))
const MakeSubmission = lazy(() => import('../pages/User/MakeSubmission/CategoryGroups'))
const Categories = lazy(() => import('../pages/User/MakeSubmission/Categories'))
const CreateMessage = lazy(() => import('../pages/User/MakeSubmission/CreateMessage'))
const ProfileScreen = lazy(() => import('../pages/Returning/ProfileScreen'))
const Submission = lazy(() => import('../pages/Uplifter/Submission'))
const SubmissionResponses = lazy(() => import('../pages/User/SubmissionResponses'))
const Conversation = lazy(() => import('../pages/User/Conversation'))
const UplifterConversation = lazy(() => import('../pages/Uplifter/Conversation'))
const Submissions = lazy(() => import('../pages/User/Submissions'))
const Response = lazy(() => import('../pages/User/Response'))

const routes = [
    <Route path="/help" exact component={Help}/>,
    <Route path="/hotlines" exact component={Hotlines}/>,
    <ProtectedRoute path="/invite" exact component={Invite}/>,
    //Uplifter
    <ProtectedUplifterRoute path="/videoRules" redirectTo="/" exact component={VideoRules}/>,
    <ProtectedUplifterRoute path="/feedback" redirectTo="/" exact component={Feedback}/>,
    <ProtectedUplifterRoute path="/uplifters/home" redirectTo="/" exact component={Recordings}/>,
    <ProtectedUplifterRoute path="/uplifters/submissions" redirectTo="/" exact component={UplifterSubmissions}/>,
    <ProtectedUplifterRoute path="/submission/:id" redirectTo="/" exact component={Submission}/>,
    <ProtectedUplifterRoute path="/recordvideo" redirectTo="/" exact component={VideoRecord}/>,
    <ProtectedUplifterRoute path="/uplifters/respond" redirectTo="/" exact component={Respond}/>,
    <ProtectedUplifterRoute path="/uplifters/conversations/:id" exact component={UplifterConversation}/>,
    <ProtectedUplifterRoute path="/rate" redirectTo="/" exact component={Rate}/>,
    <ProtectedUplifterRoute path="/completerecording" redirectTo="/" exact component={ResponseComplete}/>,
    <ProtectedUplifterRoute path="/replysent" redirectTo="/" exact component={VideoRecordSent}/>,
    <ProtectedRoute path="/logout" redirectTo="/" exact component={LogOut}/>,
    //User
    <ProtectedRoute path="/signout" redirectTo="/" exact component={SignOut}/>,
    <AuthenticatedUserRoute path="/users/responses/:id" exact component={Response}/>,
    <AuthenticatedUserRoute path="/make-submission" exact component={MakeSubmission}/>,
    <AuthenticatedUserRoute path="/make-submission/:id/categories" exact component={Categories}/>,
    <AuthenticatedUserRoute path="/make-submission/:id/categories/:category_id/create-message" exact component={CreateMessage}/>,
    <AuthenticatedUserRoute path="/users/submissions/:id" exact component={SubmissionResponses}/>,
    <AuthenticatedUserRoute path="/users/submissions" exact component={Submissions}/>,
    <AuthenticatedUserRoute  path="/conversations/:id" exact component={Conversation}/>,

    <ProtectedRoute path="/profile/personal-details" exact component={PersonalDetails}/>,
    <ProtectedRoute path="/profile/change-password" exact component={UpdatePassword}/>,
    <ProtectedRoute path="/users/more-options" exact component={ProfileScreen}/>,
    <ProtectedRoute path="/message-sent" exact component={MessageSent}/>,

    //Authentication
    <Route path="/signin" redirectTo="/" exact component={SignIn}/>,
    <Route path="/signup" redirectTo="/" exact component={SignUp}/>,
    <Route path="/forgot_password" redirectTo="/" exact component={ForgotPassword}/>,
    <Route path="/password_reset" redirectTo="/" exact component={PasswordReset}/>,
    <Route path="/confirm_code" redirectTo="/" exact component={ConfirmCode}/>,
    <Route path="/home" exact component={Home}/>,
    <Route path="/user/profile" exact component={Profile}/>,
    <UnauthenticatedUserRoute path="/onboarding" exact component={Onboarding}/>,
    <UnauthenticatedUserRoute path="/onboarding/select-topic" exact component={MakeSubmission}/>,
    <UnauthenticatedUserRoute path="/onboarding/select-topic/:id/categories" exact component={Categories}/>,
    <UnauthenticatedUserRoute path="/onboarding/select-topic/:id/categories/:category_id/create-message" exact component={CreateMessage}/>,
    <UnauthenticatedUserRoute path="/onboarding-success" exact component={Success}/>,
]

export default routes
